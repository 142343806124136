import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { login, systemLogin } from '../network/http';
import { toast, Slide } from 'react-toastify';
import { useSocket } from '../context/SocketContext';
import Loader from '../components/Loader';
import axios from 'axios';
import { GlobalContext } from '../context/GlobalContext';

const Login = () => {
    const socket = useSocket();
    const navigate = useNavigate();
    const globalCtx: any = useContext<any>(GlobalContext);
    const [user, setUser] = globalCtx.user;
    const { userCode, lang } = useParams();
    const [selectedChat, setSelectedChat] = globalCtx.chat;

    const language = lang ?? "eng";


    const [loading, setLoading] = useState<boolean>(true);

    const handleUser = async () => {
        let payload = {
            userCode: userCode
        }
        await systemLogin(payload).then((res) => {
            if (res.user) {
                const userData = res.user;
                const chatData = res.chatId;
                setUser(userData);
                localStorage.setItem("lang", language);
                localStorage.setItem("userId", userData._id);
                setSelectedChat(chatData._id)
                localStorage.setItem("user", JSON.stringify(userData));
                const userLang = language ?? "eng";
                if (userData.role === "user") {
                    setSelectedChat(chatData._id)
                    const emitData = { userIdA: userData._id, userIdB: process.env.REACT_APP_SUPPORT_ID };
                    localStorage.setItem("chatId", chatData._id);
                    socket.emit('chat:init', emitData);
                    navigate(`/user/chat/${userLang}`);
                    setLoading(false)
                } else {
                    navigate(`/support/chat/${userLang}`);
                    setLoading(false)
                }
            }
        }).catch((error) => {
            navigate(`/error/${userCode}`)
            console.log(error)
        });
    }

    handleUser();

    useEffect(() => {
    }, [])

    return (
        <>
            {
                loading && <Loader />
            }
        </>
    )
}

export default Login