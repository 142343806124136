import React, { useContext, useEffect, useState } from 'react'
import { useSocket } from '../../../context/SocketContext';
import { GlobalContext } from '../../../context/GlobalContext';
import { AiFillAudio } from "react-icons/ai";
import { FaFileImage } from "react-icons/fa";
import { GrMultimedia } from "react-icons/gr";
import axios from 'axios';
import { readMessages, unreadMessage } from '../../../network/http';
const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp', '.tiff', '.heic'];
const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv', '.flv', '.mkv', '.webm', '.mpeg', '.mpg', '.3gp'];
const audioExtensions = ['.mp3', '.wav', '.ogg', '.flac', '.aac', '.wma', '.m4a', '.ape', '.alac', '.aiff'];
const documentExtention = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt', '.rtf', '.csv'];

interface Member {
    _id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    avatar: string;
    isOnline: boolean;
}

interface ChatRoom {
    _id: string;
    logo: string;
    members: Member[];
    createdAt: string;
    updatedAt: string;
    messageUnreadCount: number,
    msg: any
}

interface ListUserProps {
    chatRoom: ChatRoom;
    keyData: string,
    activeChatId: string,
    activateChat: any,
}

interface Message {
    _id: string,
    groupId: string,
    messageText: string,
    createdBy: {
        _id: string,
        firstName: string,
        lastName: string,
        fullName: string,
        avatar: string
    }
    createdAt: string,
}

const ListUser: React.FC<ListUserProps> = ({ chatRoom, keyData, activeChatId, activateChat }) => {
    const globalCtx = useContext<any>(GlobalContext);
    const socket = useSocket();
    const loginUserId = localStorage.getItem('userId');
    const members = chatRoom.members;
    const member = members.find((member: Member) => member._id !== loginUserId);
    const initialLetter = member?.firstName.substring(1, 0);
    const chatSelected = globalCtx?.chat ?? [null, () => { }]
    const [selectedChat, setSelectedChat] = chatSelected;
    const [activeRing, setActiveRing] = useState<string>('ring-slate-100');

    const handleActivateChat = async () => {
        setSelectedChat(chatRoom._id);
        activateChat(chatRoom._id);
        localStorage.setItem("chatId", chatRoom._id);
    }


    useEffect(() => {
        socket.on('user:online', (data: string) => {
            if (member?._id === data) {
                setActiveRing('ring-green-600');
            }
        });
        return () => {
            socket.off('user:online');
        }
    }, [socket, member]);

    return (
        <div className='flex items-center relative'>
            <button className={`flex flex-row items-center hover:bg-gray-100 rounded-xl p-2 w-full ${selectedChat === keyData ? "bg-gray-100" : ""}`} key={keyData} onClick={handleActivateChat}>
                <div className={"flex items-center justify-center h-11 w-11 bg-indigo-200 rounded-full ring-2 outline-offset-4 " + activeRing}>{initialLetter}</div>
                <div className='ml-2 flex flex-col items-start'>
                    <div className="text-sm font-semibold">{member?.fullName}</div>
                    {!chatRoom?.msg && <p className='text-xs'><br /></p>}
                    {chatRoom?.msg && !chatRoom?.msg?.file && chatRoom?.msg?.messageText && <p className='text-xs'>{chatRoom?.msg?.messageText.length > 30 ? chatRoom?.msg?.messageText.substring(0, 27) + "..." : chatRoom?.msg?.messageText}</p>}
                    {chatRoom?.msg && chatRoom?.msg?.file && audioExtensions.includes(chatRoom?.msg?.file?.ext) && <div className='flex gap-1 items-center'>
                        <AiFillAudio fontSize={"15px"} /> <p className='text-xs'>Voice</p>
                    </div>}
                    {chatRoom?.msg && chatRoom?.msg?.file && imageExtensions.includes(chatRoom?.msg?.file?.ext) && <div className='flex gap-1 items-center'>
                        <FaFileImage fontSize={"15px"} /> <p className='text-xs'>Image</p>
                    </div>}
                    {chatRoom?.msg && chatRoom?.msg?.file && !imageExtensions.includes(chatRoom?.msg?.file?.ext) && !audioExtensions.includes(chatRoom?.msg?.file?.ext) && <div className='flex gap-1 items-center'>
                        <GrMultimedia fontSize={"15px"} /> <p className='text-xs'>Media</p>
                    </div>}
                </div>

            </button >
            {
                chatRoom?.messageUnreadCount > 0 && (
                    <div className='h-5 w-5 font-normal bg-green-600 text-white rounded-full text-center absolute right-4 flex items-center justify-center'>
                        <p style={{ fontSize: "10px" }}>{chatRoom?.messageUnreadCount > 99 ? "99+" : chatRoom?.messageUnreadCount}</p>
                    </div>
                )
            }
        </div>
    )
}

export default ListUser